.Card {
    margin: 30px;
    cursor: pointer;
}

.Card:hover{
    border-style: solid;
    border-color: grey;

}

.postInfo {
    display: flex;
    justify-content: space-between;
    margin: 0px 100px 0px 0px;
    text-decoration: underline;
  }

.Comment {
    margin: 0px 0px 20px 0px;
}
